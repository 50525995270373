function WellCome() {
    return (
        <div
            style={{
                textAlign: 'center',
                fontWeight: '600',
            }}
        >
            Congratulation that you've registered succesfully! Please Login to experience TikTok
        </div>
    );
}

export default WellCome;
